<template>
<section>
	<div class="fond2">
		<p class="soustitre">RECHERCHE</p>
		<div class="singledrap">
			<img class="flag" :src="imgDrapeau" @click="clickdrap">
			<label class="labdrap">{{ legd }}</label>
		</div>
	  <div class="contenDrap" :class="{ sort: sortirDrap }" @click="handleclick">
			<div class="choixdrap" data-pay="1" :class="{desact: desct == 1}"><img class="flag" src="drapeaux/fr.svg"><label class="labdrap">France</label></div>
			<div class="choixdrap" data-pay="2" :class="{desact: desct == 2}"><img class="flag" src="drapeaux/be.svg"><label class="labdrap">Belgique</label></div>
			<div class="choixdrap" data-pay="3" :class="{desact: desct == 3}"><img class="flag" src="drapeaux/ch.svg"><label class="labdrap">Suisse</label></div>
			<div class="choixdrap" data-pay="4" :class="{desact: desct == 4}"><img class="flag" src="drapeaux/qu.svg"><label class="labdrap">Québec</label></div>
		</div>
		<div class="cartep">
			<i class="material-icons globe" @click="pageWikip=chainealea()">map</i>
		</div>
	</div>
	<div id="haut" class="conteneur rech">
		<Parnom :nomcomm="nomCommune" :chpays="chgPays" :injectNm="injectNom" @nomChg="nomCommune=$event" @triggerAnc="clicBtnAnc=true" @mapcomm="donnGoogle=$event" @fermAnci="frmAnc=!frmAnc" @wikip="pageWikip=$event; efrmVisib=true" :class="{ anim_bounceIn: secouer }" />
		<div class="pile">
			<Anciennes v-show="nbrCar == 5" :chpays="chgPays" :nomcom="nomCommune" :clicSurAnc="clicBtnAnc" :fmanc="frmAnc" @ancAffichees="clicBtnAnc=false" @chgNom="onChgNom($event)" />
			<Parcodep v-show="nbrCar > 0" :chpays="chgPays" @getAncien="clicBtnAnc=true" @nouvNom="onChgNom($event)" @fermAnc="frmAnc=!frmAnc" />
		</div>
		<transition name="fade2">
			<ModalGoogle v-show="voirmodal" :donGoogle="donnGoogle" @mdlFermer="voirmodal=false" @fenbug="voirmodbug=true" @nouvWikip="pageWikip=$event; efrmVisib=true" />
		</transition>
		<transition name="fade">
			<ModalBug v-show="voirmodbug" :dGoogle="donnGoogle" @fermebug="voirmodbug=false"/>
		</transition>
		<transition name="fade">
			<Eframe v-show="efrmVisib" :pgWikip="pageWikip" @closeif="efrmVisib=false"/>
		</transition>
	</div>
</section>
</template>

<script>
  import { computed } from '@vue/runtime-core'
	import Parnom from '@/components/Parnom.vue'
	import Anciennes from '@/components/Anciennes.vue'
	import Parcodep from '@/components/Parcodep.vue'
	import ModalGoogle from '@/components/ModalGoogle.vue'
	import ModalBug from '@/components/ModalBug.vue'
	import Eframe from '@/components/Eframe.vue'

	export default {
		name: 'Recherche',
		components: { Parnom, Anciennes, Parcodep, ModalGoogle, ModalBug, Eframe },
		data() {
			return {
				items: {1: {1: 'fr', 2: 'France', 3: 5}, 2: {1: 'be', 2: 'Belgique', 3: 4}, 3: {1: 'ch', 2: 'Suisse', 3: 4}, 4: {1: 'qu', 2: 'Québec', 3: 0}},
        imgDrapeau: 'drapeaux/fr.svg',
        desct: Pays,
        legd: 'France',
        sortirDrap: false,
        nbrCar: Ncar,
        chgPays: false,
				nomCommune: '',
				injectNom: false,
				clicBtnAnc: false,
				secouer: false,
				finsecouer: false,
				voirmodal: false,
				voirmodbug: false,
				donnGoogle: {},
				efrmVisib: false,
				compt: 0,
				frmAnc: false,
				ancre: '',
				pageWikip: "about:blank"
			}
		},
		provide() {
			return {
				ncar: computed(() => this.nbrCar)
			}
		},
		mounted() {
			this.ancre = byId("haut")
		},
		methods: {
			handleclick(e) {
        Pays = e.target.closest('div').dataset.pay
        Ncar = this.nbrCar = this.items[Pays][3]
        this.desct = Pays
        this.imgDrapeau = 'drapeaux/' + this.items[Pays][1] + '.svg'
        this.legd = this.items[Pays][2]
				this.chgPays = !this.chgPays
        this.sortirDrap = false
      },
      clickdrap() {
        this.sortirDrap = !this.sortirDrap
      },
			onChgNom(n) {
				this.nomCommune = n
				this.injectNom = !this.injectNom
				if (!this.finsecouer) {
					this.secouer = true
					setTimeout(() => {
						this.secouer = false
						this.finsecouer = true
						this.ancre.scrollIntoView({block: 'start', behavior: 'smooth'})
					}, 500)
				}
			},
			chainealea() {
				let chn = "ab"
				let r = chn[this.compt]
				this.compt = 1 - this.compt
				this.efrmVisib = true
				return r
			}
		},
		watch: {
			// Données pour nouvelle carte Google
			donnGoogle() {
				this.voirmodal = true
			}
		}
	}
</script>
