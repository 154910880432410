<template>
	<div class="modal mbug">
		<div class="modal-dialog">
			<div class="modal-content mdlbug">
				<p>La description de la commune n'apparaît pas sous la carte, ou est erronée&nbsp;? Une réponse affirmative enverra l'information au webmaster, qui vous en remercie.</p>
				<div class="modal-footer footbug">
					<button class="grand" @click="refermer">Annuler</button>
					<button class="grand oui" @click="prevenir">Oui</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ModalBug',
		props: ['dGoogle'],
		methods: {
			refermer() {
				this.$emit('fermebug')
			},
			async prevenir() {
				let res = await this.axios.get("php/pageinapp.php?pays=" + Pays + "&nom=" + this.dGoogle['nom'] + "&cp=" + this.dGoogle['codep']);
				this.refermer()
			}
		}
	}
</script>
