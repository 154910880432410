import { createApp } from 'vue'
import App from './App.vue'
// import $ from 'jquery'
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@/assets/js/global.js'
import '@/assets/js/latinise.min.js'
// import '@/assets/js/luxon.min.js'
// import '@/assets/js/clipboard.min.js'
import '@/assets/styles.min.css'

// Installation d'axios : npm install --save vue-axios

// createApp(App).mount('#app')
createApp(App).use(VueAxios, axios).mount('#app')	// , { autoSetContainer: true, appendToBody: true }

// import router from './router'
// createApp(App).use(router).mount('#app')
