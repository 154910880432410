<template>
	<section class="informations">
		<p>Cliquez sur "Recherche" en haut de votre écran. Dans la boîte "Communes", après avoir sélectionné un pays, saisissez le nom partiel de la commune dont vous désirez obtenir l'orthographe exacte, le code postal (sauf Québec), la géolocalisation et/ou les informations fournies par Wikipédia.</p>
		<p>Lorsqu'une commune n'est pas trouvée dans la base de données du pays elle est systématiquement recherchée sur Wikipédia, car il peut s'agir d'une commune, ancienne ou non, existant dans un autre pays. En cas de succès une courte description est affichée ; cliquer dessus ouvre la page Wikipédia concernée.<br>Exemple de fonctionnement : vous sélectionnez la Suisse et tapez <span class="font500i">la heutte</span> ; la réponse est <span class="font500i">Péry-La Heutte</span>. Si maintenant vous sélectionnez la France, vous obtenez pour réponse "Ancienne commune de Suisse".<br>
		Notez que la recherche sur Wikipédia est assujettie à l'orthographe exacte du nom que vous saisissez, mais qu'elle est insensible à la casse et à l'accentuation. Pour reprendre notre exemple, si vous tapez <span class="font500i">heutte</span> après avoir sélectionné la Suisse vous obtiendrez toujours <span class="font500i">Péry-La Heutte</span> ; mais après sélection de la France vous n'aurez aucun résultat, car cette séquence de lettres n'existant dans aucun nom de commune française elle est recherchée sur Wikipédia, qui connaît <span class="font500i">la heutte</span> mais pas <span class="font500i">heutte</span>.</p>
		<p class="font500">A savoir</p>
		<ul>
			<li>En mode de fonctionnement normal, les noms de communes affichés incluent tous la chaine de caractères que vous avez saisie. En mode strict, le nom de la commune affichée (si elle existe) correspond exactement à la chaine saisie. Par exemple, vous ne pourrez trouver la commune de <span class="font500i">Ham</span> qu'en mode strict, car elle n'est pas affichée dans les 300 premiers résultats (limite). Ou encore, la commune de <span class="font500i">Gy</span> ne peut pas être trouvée en mode normal, car le minimum de lettres saisies doit être 3 ; vous devez alors passer en mode strict, qui lève cette limitation. Les deux modes sont insensibles à la casse, à l'accentuation et à la graphie (espaces ou traits d'union dans les noms composés).</li>
			<li>Lorsque plusieurs communes possèdent le même nom au sein du même pays, il apparaît en bleu dans la liste des résultats.</li>
			<li>Lorsque le bouton "Anciennes" apparaît, cela signifie qu'il existe des anciennes communes dont le nom correspond à votre saisie. Cliquez dessus pour les afficher.</li>
			<li>Dans les noms francophones, les Saint et Sainte sont respectivement remplacés par St et Ste. Seul le pluriel est conservé (Saints et Saintes). Si les mots Saint ou Sainte figurent dans le nom composé que vous saisissez, ils sont automatiquement convertis.</li>
			<li>Le code postal des arrondissements, quartiers ou sections (selon le pays) ne figure pas dans la base de données. Elle contient un seul code postal par ville. Pour Paris il s'agit de 75000, pour Lyon 69000, etc.</li>
			<li>L'altitude indiquée pour chaque commune est celle du centre de la carte, sous le marqueur, ce qui constitue une bonne approximation. Pour une île elle correspond à celle du plus haut sommet divisée par deux.</li>
			<li>Sachez à toutes fins utiles que la Poste française déconseille l'utilisation de traits d'union dans le nom d'une commune écrit sur une enveloppe, ceci pour des raisons techniques.</li>
		</ul>
		<p class="font500">Pages Wikipédia</p>
		<p>De très nombreuses personnes créent les pages Wikipédia, si bien que leur titre (en l'occurrence le nom d'une commune) ne respecte pas toujours les mêmes règles, surtout d'un pays à l'autre. C'est pourquoi il peut arriver qu'une page d'homonymie ou de titre introuvable s'affiche lorsque vous cliquez sur le bouton "Wikipédia" sous la carte, ou que la courte description de la commune, toujours sous la carte, soit erronée ou absente. Si vous en êtes témoin vous pouvez cliquer sur l'icône "insecte" (bug) afin de signaler le problème au webmaster.</p>
		<p class="font500">Note</p>
		<p>Les bases de données ont été soigneusement vérifiées<sup>1</sup>. Cependant, merci de signaler par la page Contact toute inexactitude que vous constateriez.<br><span style="font-size:14px">1. Pour la France, la géolocalisation à 6 décimales, ainsi que l'altitude, sont en cours.</span></p>
	</section>
</template>
