<template>
  <div class="menu">
    <ul id="lst">
      <li id="premitem" @click="pageActive = 'Accueil'" :class="{ 'disab': pageActive == 'Accueil' }">Accueil</li>
      <li @click="pageActive = 'Recherche'" :class="{ 'disab': pageActive == 'Recherche' }">Recherche</li>
      <li @click="pageActive = 'Geoloc'" :class="{ 'disab': pageActive == 'Geoloc' }">Géocodage</li>
      <li @click="pageActive = 'Contact'" :class="{ 'disab': pageActive == 'Contact' }">Contact</li>
    </ul>
  </div>
  <transition name="component-fade" mode="out-in">
    <keep-alive exclude="Contact">
      <component :is="pageActive" />
    </keep-alive>
  </transition>
</template>

<script>
  import Accueil from './components/nav/Accueil.vue'
  import Recherche from './components/nav/Recherche.vue'
  import Geoloc from './components/nav/Geoloc.vue'
  import Contact from './components/nav/Contact.vue'

  export default {
    name: 'App',
    components: {Accueil, Recherche, Geoloc, Contact},
    mounted() {
      byId('premitem').click()
    },
    data() {
      return {
        pageActive: Accueil
      }
    }
  }
</script>
