<template>
	<div class="modal">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<i class="material-icons close" @click="fermerCarte">cancel</i>
				</div>
				<!-- Contenu -->
				<div class="map">
					<GoogleMaps :dnGoogle="donGoogle" :proven="'recherche'" />
				</div>
				<div class="modal-footer">
					<div class="nomcomm">
						<ul class="leg">
							<li v-html="descript"></li>
							<li class="separ">&nbsp;</li>
							<li v-html="altitude"></li>
							<li class="floroot"><span>lat, lng : </span><span id="crd" class="coord">{{ coordon }}</span><span class="material-icons btncopy" @click="copie">content_copy</span></li>
						</ul>
					</div>
				</div>
				<div class="btncarte">
					<button class="grand vert" @click="ouvreWikip">Wikipédia</button>
					<button class="grand orange" @click="fermerCarte">Fermer</button>
					<button class="grand gris"><i class="material-icons bug" @click="this.$emit('fenbug')">bug_report</i></button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import GoogleMaps from './GoogleMaps.vue'

	export default {
		name: 'ModalGoogle',
	  components: { GoogleMaps },
		props: ['donGoogle'],
		data() {
			return {
				lenom: '',
				altitude: 0,
				coordon: '',
				descript: '',
				parser: new DOMParser(),
				cnom: '',
				range: new Range()
			}
		},
		methods: {
			fermerCarte() { this.$emit('mdlFermer') },
			description() {
				this.descript = ''
				let ajdept = ''
				let outremer = false
				let hmn = this.donGoogle['homon']
				this.cnom = this.lenom.replace(/St(\W{1})/g, "Saint$1").replace(/Ste(\W{1})/g, "Sainte$1");
				let cregion = Regions[Pays][this.donGoogle['region']];
				if (Pays == 1) {	// France
					let tmp = this.donGoogle['dept']
					ajdept = Departm[tmp]
					outremer = (tmp.length == 3)
				} else if (Pays == 4) {	// Québec
					ajdept = this.donGoogle['codep']
				} else {	// Belgique et Suisse
					ajdept = cregion
				}

				if (hmn < 9) {
					if (Pays == 1) {
						let tem = false
						if (hmn == 0) {
							let res = this.pageExiste(this.cnom, this.donGoogle['id'])
							if (res == "oui") tem = true
						} else tem = true

						if (tem) {
							if (outremer) this.cnom += " (" + cregion + ")"; else this.cnom += " (" + ajdept + ")"
						}
					} else if (Pays == 2) {
						if (hmn == 2) {
							this.cnom += " (Belgique)"
						} else if (hmn == 4) {
							this.cnom += " (ville)"
						} else if (hmn == 5) {
							this.cnom += " (commune)"
						} else if (hmn == 6) {
							this.cnom = "Ville de Bruxelles"
						} else {	// hmn = 1 ou 3
							this.cnom += " (" + cregion + ")"
						}
					} else if (Pays == 3) {
						if (hmn == 2) {
							this.cnom += " (Suisse)"
						} else if (hmn == 4) {
							this.cnom += " (ville)"
						} else if (hmn == 5) {
							this.cnom += " (commune)"
						} else if (hmn == 6) {
							this.cnom += " (ville suisse)"
						} else if (hmn == 7) {
							this.cnom += " (homonymie)"
						} else {	// hmn = 1 ou 3
							this.cnom += " (" + ajdept + ")"
						}
					} else {
						if (hmn == 1 || hmn == 3) {
							this.cnom += " (" + ajdept + ")"
						} else if (hmn == 2) {
							this.cnom += " (Québec)"
						} else if (hmn == 4) {
							this.cnom += " (ville)"
						} else if (hmn == 5) {
							this.cnom += " (municipalité)"
						} else if (hmn == 6) {
							this.cnom += " (" + cregion + ")"
						} else if (hmn == 7) {
							this.cnom += " (village)"
						} else {	// hmn = 8
							this.cnom += " (réserve indienne)"
						}
					}
				}

				this.courteDescr(this.cnom)
			},
			async pageExiste(a, b) {
				// Regarder si la page Wikipédia de cette commune existe
				let res = await this.axios.get("php/pagexiste.php?nom=" + a + "&id=" + b)
				return res.data
			},
			async courteDescr(nom) {
				// Récupérer la courte description sur Wikipédia
				let res = await this.axios.get("https://fr.wikipedia.org/w/api.php?format=xml&action=query&prop=extracts&exintro&explaintext&redirects=1&origin=*&titles=" + nom)
				let xmlDoc = this.parser.parseFromString(res.data,"text/xml")
				try {
					let r = xmlDoc.getElementsByTagName("extract")[0].childNodes[0].nodeValue
					if (r.length > 500) r = r.substr(0, 499) + ' ...';
					if (nom.includes('(')) nom = nom.replace(/ *\([^)]*\)*/, '')
					// r = r.replace(nom, '<b>' + nom + '</b>')
					this.descript = r.replace(nom, '<b>' + nom + '</b>')
					// console.log(nom,'\n',this.descript)
				} catch (error) {
					console.log("L'extrait requis sur Wikipédia n'existe pas")
				}
			},
			ouvreWikip() {
				this.$emit('nouvWikip', this.cnom)
			},
			copie() {
        let elem = null
        document.getSelection().removeAllRanges()
        elem = byId('crd')
        this.range.setStart(elem, 0)
        this.range.setEnd(elem, 1)
        document.getSelection().addRange(this.range)
        document.execCommand('copy')
        setTimeout(() => {
          document.getSelection().removeAllRanges()
        }, 100)
			}
		},
		watch: {
			// Données pour nouvelle carte Google
			donGoogle() {
				let c = this.donGoogle['coord'].split(',')
				this.lenom = this.donGoogle['nom']
				this.altitude = 'altitude : <span class="coord">' + this.donGoogle['alt'] + ' m</span>'
				this.coordon = (c[0] + ', ' + c[1]).toString()
				this.description()
			}
		}
	}
</script>
