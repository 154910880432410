<template>
  <GoogleMap api-key="AIzaSyBTQJOn5-kL5-lZfbnuItiNjFE6jEoM83c" class="carte" :center="center" :zoom="zoum" @click="clicSurCarte($event)" :streetViewControl="false">
    <Marker :options="{ position: center, icon: 'markred.png', opacity: 0.5 }" />
  </GoogleMap>
</template>

<script>
import { defineComponent } from 'vue'
import { GoogleMap, Marker } from 'vue3-google-map'

export default defineComponent({
	name: 'GoogleMaps',
  components: { GoogleMap, Marker },
	props: ['dnGoogle', 'proven'],
	data() {
		return {
			zoum: 3,
			center: { lat: 0, lng: 0 },
			chaine: "971 972 974 975"
		}
	},
	methods: {
		facteurzoom(cp) {
			let zm = 7;	// Belgique, Suisse
			if (Pays == 1) {
				if (cp == "97133") zm = 13;	// St-Barthélemy
				else if (cp == "97150") zm = 12;	//St-Martin
				else if (cp.substring(0, 2) == "20") zm = 8;	// Corse
				else {
					cp = cp.substring(0, 3);
					if (this.chaine.includes(cp)) zm = 9;
					else if (cp == "973") zm = 7;
					else if (cp == "988") zm = 8;
					else if (cp == "976") zm = 11;
					else if (cp == "986") zm = 11;
					else if (cp == "987") zm = 10;
					else zm = 6
				}
			} else {
				if (Pays == 4) zm = 6	// Québec
			}

			return zm
		},
		forceZoom() {
			this.zoum = 0;
			this.$nextTick().then(() => {
				if (this.proven == 'recherche') this.zoum = this.facteurzoom(this.dnGoogle['codep']);
				else this.zoum = 14
			});
		},
		clicSurCarte(e) {
			if (this.proven == 'geocoder') {
				this.$emit('clicCarte', e)
			}
		}
	},
	watch: {
		dnGoogle() {
			let c = this.dnGoogle['coord'].split(',')
			this.center = { lat: parseFloat(c[0]), lng: parseFloat(c[1]) }
			this.forceZoom()
		}
	}
})
</script>
