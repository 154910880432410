<template>
	<div class="vert-ensemb">
    <div class="borduretop largeCdp">Codes postaux</div>
		<div class="topcontent largeCdp">
			<div class="champ-nom">
				<input type="text" id="lecodp" :placeholder="ncar.value + ' chiffres'" autocomplete="off" v-model="code" @input="envoiCode(code)">
				<span class="nbrreslt">{{ nbr }}</span>
				<span class="material-icons ferme" v-show="code !== ''" @click="viderCode()">cancel</span>
				<button class="small btnAncien" v-if="anccodexst && codepTrouve" @click="AfficheAnc()">ANCIENNES</button>	<!--  @click="AffichAnc" -->
			</div>
		</div>
		<div class="card scroller">
			<div class="resultats" v-if="codepTrouve" v-html="chaine" @click="handleClicks($event)"></div>
			<div v-else><p class="noresult">Aucun résultat</p></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Parcodep',
		inject: ['ncar'],
		props: ['chpays'],
		data() {
			return {
				donnees: {},
				chaine: '',
				codepTrouve: false,
				anccodexst: false,
				code: '',
				nbr: 0
			}
		},
		methods: {
			async envoiCode(n) {
				if (!n) this.codepTrouve = false
				if (n.length == Ncar) {
					let res = await this.axios.get("php/codespostaux.php?cp=" + n + "&pays=" + Pays)
					if (typeof(res.data) !== "string") {
						this.donnees = res.data
						this.chaine = '<ul class="listres">' + '<li class="nomregion">' + Regions[Pays][this.donnees[0][2]]
						if (Pays == 1) {
							 this.chaine += '<span class="departm font400">' + Departm[this.donnees[0][6]] + '</span>'
						}

						this.chaine += '</li>'

						this.donnees.forEach((donn, index) => {
							this.chaine += '<li data-ind="' + index + '">' + donn[0] + '</li>'
						})

						this.chaine += '</ul>'
						this.nbr = this.donnees.length
						this.codepTrouve = true
						// Regarder s'il existe d'anciennes communes ayant ce code postal
						if (Pays == 1) {
							res = await this.axios.get("php/existancodp.php?codp=" + n)
							this.anccodexst = (res.data == 1 ? true : false)
						}
					} else {
						this.codepTrouve = false
					}
				}
			},
			handleClicks(e) {
				let cible = e.target
				let res = cible.innerText
				if (cible.dataset.ind !== undefined) {
					if (cible.getElementsByClassName('oeil').length == 0) {
						cible.innerHTML += '<i class="material-icons oeil">visibility</i>'
					} else {
						res = res.replace('visibility', '')
					}
					this.$emit('nouvNom', res)
      	}
			},
			AfficheAnc() {
				ClicAncOrign = 1	// origine du clic sur le bouton Anciennes
				ChaineRecherche = this.code
				this.$emit('getAncien')
			},
			viderCode() {
				if (ChaineRecherche == this.code) {
					ChaineRecherche = ''
					this.$emit('fermAnc')
				}
				this.code = ''
				this.codepTrouve = false
        this.anccodexst = false
        this.nbr = 0
				byId('lecodp').focus()
			}
		},
		watch: {
			chpays() {
				this.codepTrouve = false
				this.code = ''
				this.nbr = 0
			}
		}
	}
</script>
