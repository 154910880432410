<template>
	<div class="vert-ensemb">
		<div class="borduretop small">Anciennes communes</div>
		<div class="topcontent small"><span class="font15">Recherché :</span> <span class="chnrech">{{ chaineRech }}</span><span class="material-icons fermer" v-show="chaine != ''" @click="fermer">cancel</span> </div>
		<div class="card scroller">
			<div class="resultats" v-html="chaine" @click="handleClicks($event)"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Anciennes',
		props: ['nomcom', 'clicSurAnc', 'chpays', 'fmanc'],
		data() {
			return {
				donnees: {},
				chaine: '',
				chaineRech: '',
				est: ' &rArr; '
			}
		},
		methods: {
			async getListeAnc() {
				let m = 0, chn= '', cls, em = '';
				let res = await this.axios.get("php/paranc.php?mot=" + ChaineRecherche + "&tem=" + ClicAncOrign + "&mode=" + m);
				if (typeof(res.data) !== "string") {
					this.donnees = res.data;
					chn = '<ul class="listres">';
					if (ClicAncOrign == 0) {	// ancien nom
						this.donnees.forEach((donn, index) => {
							cls = '';
							if (donn[4] == 0) cls = '<span class="nexisteplus">' + donn[0] + '</span>'; else cls = donn[0]
							chn += '<li data-ind="'+ index + '">' + cls
							if (donn[3] == em) {
								chn += '<span class="complus">' + this.est + donn[3] + '</span></li>'
							} else {
								chn += this.est + donn[3] + '<span class="departm">' + Departm[donn[2].slice(0,2)] + '&nbsp' + donn[2] + '</span></li>';
								em = donn[3]
							}
						});
					} else {	// ancien code postal
						this.donnees.forEach((donn, index) => {
							cls = '';
							if (donn[4] == 0) cls = '<span class="nexisteplus">' + donn[0] + '</span>'; else cls = donn[0];
							chn += '<li data-ind="'+ index + '">' + cls
							if (donn[3] == em) {
								chn += '<span class="complus">' + this.est + donn[3] + '</span>'
							} else {
								chn += this.est + donn[3]
								if (ChaineRecherche != donn[2]) chn += '<span class="departm">' + donn[2] + '</span>'
								em = donn[3]
							}
							chn += '</li>'
						});
					}
					chn += '</ul>';
					this.chaine = chn
					this.chaineRech = ChaineRecherche
					this.$emit('ancAffichees')
				}
			},
			fermer() {
				this.chaine = ''; this.chaineRech=''
			},
			handleClicks(e) {
				let cible = e.target.closest('li')
        let indx = cible.dataset.ind
				let res = cible.innerText
        if (indx !== undefined) {
					if (cible.getElementsByClassName('oeil').length == 0) {
						cible.innerHTML += '<i class="material-icons oeil">visibility</i>'
					} else {
						res = res.replace('visibility', '')
					}
					this.$emit('chgNom', this.donnees[indx][3])
        }
      },
		},
		watch: {
			// Cliqué sur bouton "Anciennes"
			clicSurAnc(nouv) {
				if (nouv) this.getListeAnc()
			},
			chpays() {
				this.fermer()
			},
			fmanc() {
				this.fermer()
			}
		}
	}
</script>
