<template>
  <section style="position: relative">
    <!-- <img id="photo" src="@/assets/imgs/village.jpg" alt=""> -->
    <picture>
      <source srcset="@/assets/imgs/village2.jpg" class="photo" media="(max-width: 800px)">
      <img class="photo" src="@/assets/imgs/village.jpg" alt="">
    </picture>
    <p class="titre">Communes de France et d'ailleurs</p>
    <div class="conteneur">
      <p class="intro colonne"><span class="ital">Communes de France et d'ailleurs</span>&nbsp; exploite 4 bases de données de communes majoritairement francophones, celles de France, de Belgique, de Suisse et du Québec, dont la création et la vérification s'est étalée sur plusieurs années. Elles sont nées du constat qu'aucune base consultée en ligne ou téléchargée n'est vraiment fiable, trop de changements étant intervenus depuis leur création sans avoir été pris en compte. Contrairement à une idée répandue, le nom et le statut des communes peut en effet varier. Ces dernières années, en France, 2448 d'entre elles ont ainsi acquis le statut d'ancienne commune pour avoir été absorbées par une voisine, qui souvent change de nom, ou être devenues des communes déléguées suite à un regroupement. Elles sont ici clairement identifiées.</p>
      <transition name="component-fade" mode="out-in">
        <InfosPays v-if="charg" />
        <div v-else><i class="material-icons ouvrinfs" @click="ouvreInfos">add_circle</i></div>
      </transition>
    </div>
  </section>
</template>

<script>
  import InfosPays from '@/components/InfosPays.vue'

  export default {
    name: 'Accueil',
    components: { InfosPays },
    data() {
      return {
        charg: false
      }
    },
    methods: {
      ouvreInfos() {
        this.charg = true
      }
    }
  }
</script>
