<template>
  <div class="wikipd">
		<div class="bswiki">
			<div class="fermeif" @click="$emit('closeif')">Fermer cette fenêtre</div>
		</div>
		<iframe id="pageWiki" width="100%" height="100%" frameborder="0" seamless :class="{ content: cls }"></iframe>	<!--  gesture="media" -->
  </div>
</template>

<script>
	export default {
		name: 'Eframe',
		props: ['pgWikip'],
		data() {
			return {
				cls: false
			}
		},
		watch: {
			pgWikip() {
				if (this.pgWikip.length == 1) {
					this.cls = true
					byId('pageWiki').contentWindow.location.replace('carte.html?p=' + Pays)
				} else {
					this.cls = false
					byId('pageWiki').contentWindow.location.replace('vide.html')
					setTimeout(() => {
						byId('pageWiki').contentWindow.location.replace('https://fr.m.wikipedia.org/wiki/' + this.pgWikip)
					}, 200)
				}
			}
		}
	}
</script>
