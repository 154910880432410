<template>
	<section class="informations">
		<p>Dans la page Géocodage, saisissez l'adresse dont vous désirez connaître les coordonnées géographiques (latitude, longitude). Parmi les informations renvoyées, deux méritent une attention particulière : la correspondance (du résultat) et l'adresse géocodée. Si la première indique "partielle", la seconde ne correspond pas exactement à l'adresse saisie, qu'il est dans ce cas recommandé de vérifier, car les coordonnées géographiques affichées sont celles de l'adresse géocodée.<br><br>De nombreux lieux, monuments, entreprises et commerces disposent d'un point d'intérêt dans Google Maps, et sont donc disponibles dans le géocodeur. Trois possibilités se présentent :</p>
		<ul>
			<li>Vous cherchez à localiser un point d'intéret spécifique, tel que "Mairie de Montréal, Canada", "Château de Chambord", "Macif, Niort", etc. Dans ce cas, le champ "Code postal &amp; ville" doit être vide (cliquez sur la croix sur sa droite).</li>
			<li>Vous cherchez un commerce situé dans un lieu précis, comme par exemple "Renault Gomez", auquel cas vous devez ajouter "31000 Toulouse" dans le champ "Code postal &amp; ville". Le géocodeur renverra l'adresse exacte et les coordonnées géographiques du garage en question, même si le code postal que vous avez saisi n'est pas tout à fait exact, l'important étant d'indiquer au géocodeur un endroit proche.</li>
			<li>Vous pouvez renseigner l'adresse complète dans le premier champ et laisser le second vide. Exemple : "Renault Stecav Lacan, 31000 Toulouse". Le géocodeur renverra les coordonnées géographiques et l'adresse postale du garage, situé à L'Union dans la banlieue toulousaine.</li>
		</ul>
		<p class="font500">Clic sur la carte</p>
		<p>Lorsque la carte est ouverte, cliquez à l'endroit que vous voulez pour obtenir ses coordonnées géographiques, qui sont alors affichées dans le champ "Coordonnées du clic". Si vous connaissez la région vous pouvez faciliter la recherche d'un lieu précis en passant en mode Satellite, et éventuellement en plein écran (bouton en haut à droite).</p>
		<p class="font500">Géocodage inverse</p>
		<p>Cliquez que le bouton correspondant en haut à droite de votre écran, puis saisissez les coordonnées géographiques du lieu dont vous désirez obtenir des informations. Le résultat n'est pas toujours exact, chaque lieu ne disposant pas d'une adresse précise. C'est le lieu le plus proche connu de Google Maps qui sera privilégié (en général une route).</p>
	</section>
</template>
