<template>
  <section>
    <div class="fond3">
      <p class="soustitre">CONTACT</p>
      <button class="grand btnfairedon" @click="donPaypal">Faire un don</button>
    </div>
    <div class="conteneur rech">
      <form class="boite" @submit.prevent="soumettre">
        <div>
          <label for="nom">Votre nom</label>
          <input type="text" id="nom" v-model="forme.nom">
        </div>
        <div>
          <label for="email">Votre email</label>
          <input type="text" id="email" v-model="forme.email">
        </div>
        <div class="stl1">
          <label for="msg">Votre message</label>
          <textarea id="msg" class="autoExpand" v-model="forme.messg"></textarea>
        </div>
        <div class="stl2">
          <p>{{ quest }}</p>
          <input type="text" id="verif" placeholder="anti-robots" @blur="addit=false" autocomplete="off" style="margin-left: 10px; width: 80px">
        </div>
        <div class="stl3">
          <button class="grand" :class="{ disab: dsb}">Envoyer</button>
        </div>
        <div class="alerte" v-show="alert2" :class="{ 'zoomIn': alert2 }"><span>{{ mesg }}</span></div>
        <div class="bon" v-show="resok" :class="{ 'zoomIn': resok }">Message envoyé. Merci !</div>
      </form>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'Contact',
    data() {
      return {
        forme: {
          nom: '',
          email: '',
          messg: ''
        },
        cptch1: 0,
        cptch2: 0,
        total: 0,
        quest: '',
        dsb: false,
        mesg: '',
        alert2: false,
        resok: false
      }
    },
    mounted() {
      this.cptch1 = this.random(10, 19)
      this.cptch2 = this.random(1, 9)
      this.total = this.cptch1 + this.cptch2
      this.quest = this.cptch1 + " + " + this.cptch2 + " ="
    },
    methods: {
      soumettre(e) {
        if (parseInt(byId('verif').value) !== this.total) this.messag("L'addition est erronée");
        else if (this.forme.nom == '' || this.forme.email == '' || this.forme.messg == '') this.messag("Tous les champs doivent être renseignés");
        else {
          this.dsb = true
          this.envoimesg()
        }
      },
      messag(txt) {
        this.mesg = txt
        this.alert2 = true
        setTimeout(() => {
          this.alert2 = false
        }, 3000);
      },
      random(m, n) {
        m = parseInt(m)
        n = parseInt(n)
        return Math.floor(Math.random() * (n - m + 1)) + m
      },
      async envoimesg() {
        let jsn = JSON.stringify({ nom: this.forme.nom, email: this.forme.email, msg: this.forme.messg })
        await this.axios.post('php/envContact.php', jsn).then((res) => {
          if (res.data === 'toutVaBien') {
            this.resok = true
            setTimeout(() => {
              this.resok = false
              this.dsb = false
              this.forme.nom = this.forme.email = this.forme.messg = ''
              byId('verif').value = ''
            }, 3000)
          }
          else this.messag("Une erreur s'est produite. Veuillez réessayer ultérieurement")
        }, (error) => {
          console.log(error)
        })
      },
      donPaypal() {
        window.open('https://www.paypal.com/donate?business=LTUR5TPP5JGU6&no_recurring=0&item_name=Services+en+ligne&currency_code=EUR', '_blank')
      }
    }
  }
</script>
