<template>
	<section class="informations">
		<p>
			<span class="nompays">Belgique</span>
			<br>Les fusions de communes ont eu lieu entre 1975 et 1983, puis au 1er janvier 2019 en Flandre.
		</p>
		<p>
			<span class="nompays">Suisse</span>
			<br>Lorsqu'à la suite d'une fusion de communes l'une d'elles a donné son nom à l'ensemble, c'est son code postal qui est utilisé. Lorsque le nom de l'ensemble est nouveau, c'est le plus petit code postal qui a été retenu. Notez que les codes postaux du Liechtenstein figurent également (il apparaîtra comme un canton).
		</p>
		<p>
			<span class="nompays">Québec</span>
			<br>Aucune commune ne pouvant être identifiée de manière univoque par un code postal, ils ne sont pas utilisés. Chaque commune est suivie du nom de la MRC (municipalité régionale de comté) à laquelle elle appartient.
		</p>
	</section>	
</template>

<style>
	.nompays {
		font-weight: 500;
	}
</style>
