<template>
	<section class="informations">
		<p>La base de données contient les communes de métropole et d'Outre-mer. Sa dernière mise à jour date de janvier 2024. Le sigle DROM signifie "Département et Région d'Outre-mer", et COM signifie "Collectivité d'Outre-mer". Leurs statuts sont différents.</p>
		<p>Sont définies comme anciennes communes toutes celles qui se sont regroupées avec l'une ou plusieurs de leurs voisines. Le statut de chacune d'elles évolue de diverses manières : </p>
		<ul>
			<li>Elle renonce à son statut de collectivité territoriale et de division administrative ; autrement dit, elle est purement et simplement absorbée par sa voisine dont elle intègre le territoire. Elles sont peu nombreuses et sont affichées en rouge : <span style="color:#BF3232">Badinières</span> &rArr; Eclose-Badinières. Badinières a été absorbée par Eclose pour former Eclose-Badinières ; elle a donc cessé d'exister.</li>
			<li>Elle devient une commune déléguée, c'est-à-dire qu'elle demeure une division administrative dirigée par un maire délégué aux pouvoirs restreints. C'est le statut de l'immense majorité des communes regroupées&nbsp;: Germagnat &rArr; Nivigne et Suran. Germagnat est une commune déléguée de Nivigne et Suran.</li>
			<li>Elle devient le chef-lieu (ou siège) de la commune nouvelle et peut conserver son nom ou en changer. Si elle le conserve elle n'acquiert pas le statut d'ancienne commune ; dans le cas contraire son ancien nom figure dans leur liste. Dans l'exemple précédent, Chavannes-sur-Suran est devenue le chef-lieu de la commune nouvelle de Nivigne et Suran, son nouveau nom, et figure donc sous le nom de Chavannes-sur-Suran dans la liste des anciennes communes.</li>
		</ul>
		<p>Une commune peut également changer de nom sur simple décision de sa part.</p>
		<p>Voir <a href="https://fr.wikipedia.org/wiki/Commune_nouvelle" target="_blank">cette page</a> pour de plus amples détails.</p>
	</section>
</template>
